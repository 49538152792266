#push-to-dms-dialog {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

#push-to-dms-dialog-stock-number-input {
    height: 35px;
}

#push-to-dms-dialog-copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: medium;
    height: 35px;
}
