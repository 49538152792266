#confirm-push-to-dms-dialog,
#confirm-push-to-dms-dialog-loading-container {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

#confirm-push-to-dms-dialog-loading-container .modal-dialog {
    min-width: 200px;
}

#confirm-push-to-dms-dialog-loading-container .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 192px;
}

#confirm-push-to-dms-dialog-loading-container .modal-header {
    display: none;
}
