#ids-menu-root-deal-type-selector {
    .ids-menu-item {
        padding-left: 26px;
        color: black;
        svg {
            display: none;
        }
        &.selected-item {
            padding-left: 12px;
            svg {
                display: block;
                font-size: 14px;
                color: #003468;
            }
        }
    }
}
